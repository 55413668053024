import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Login",
      meta: {
        hideMenu: true,
        skipAuthentication: true,
      },
      component: () => import("../views/Authentication/Login"),
    },
    {
      path: "/login/admin",
      name: "AdminLogin",
      meta: {
        hideMenu: true,
        skipAuthentication: true,
      },
      component: () => import("../views/Authentication/AdminLogin.vue"),
    },
    {
      path: "/login/manager",
      name: "ManagerLogin",
      meta: {
        hideMenu: true,
        skipAuthentication: true,
      },
      component: () => import("../views/Authentication/ManagerLogin"),
    },
    {
      path: "/privacy-policy",
      name: "PrivacyPolicy",
      meta: {
        hideMenu: true,
        skipAuthentication: true,
      },
      component: () => import("../views/PrivacyPolicy.vue"),
    },
    {
      path: "/terms-of-use",
      name: "TermsOfUse",
      meta: {
        hideMenu: true,
        skipAuthentication: true,
      },
      component: () => import("../views/TermsOfUse.vue"),
    }, 
    {
      path: "/form/password",
      name: "PasswordForm",
      meta: {
        hideMenu: true,
        skipAuthentication: true,
      },
      component: () => import("../views/Authentication/PasswordForm.vue"),
    },
    {
      path: "/password/:token",
      name: "ResetPassword",
      meta: {
        hideMenu: true,
        skipAuthentication: true,
      },
      component: () => import("../views/Authentication/ResetPassword"),
    },
    {
      path: "/login/attendance",
      name: "AttendanceLogin",
      meta: {
        hideMenu: true,
        skipAuthentication: true,
      },
      component: () => import("../views/Authentication/AttendanceLogin.vue"),
    },

    {
      path: "/dashboard",
      name: "Dashboard",
      meta: {
        hideMenu: false,
        permissions: ["doctor"],
      },
      component: () => import("../views/Dashboard/Dashboard"),
    },
    {
      path: "/patient/:id",
      name: "Patient",
      meta: {
        permissions: ["doctor"],
      },
      component: () => import("../views/Patient/Profile"),
    },
    {
      path: "/patient/:id/attendance/:attendance_id",
      name: "PatientShowAttendance",
      meta: {
        permissions: ["doctor"],
      },
      component: () => import("../views/Patient/Attendance/View.vue"),
    },
    {
      path: "/patient/:id/anamnese",
      name: "AnamneseAnswer",
      meta: {
        permissions: ["doctor"],
      },
      component: () => import("../views/Patient/Anamnese/Form/View.vue"),
    },
    {
      path: "/patient/:id/anamnese/new",
      name: "NewAnamneseAnswer",
      meta: {
        permissions: ["doctor"],
      },
      component: () => import("../views/Patient/Anamnese/Form/FormAnswer.vue"),
    },
    {
      path: "/patient/:id/anamnese/update/:form_application_id",
      name: "UpdateAnamneseAnswer",
      meta: {
        permissions: ["doctor"],
      },
      component: () => import("../views/Patient/Anamnese/Form/FormAnswer.vue"),
    },
    {
      path: "/create_patient",
      name: "CreatePatient",
      meta: {
        permissions: ["doctor"],
      },
      component: () => import("../views/Patient/Form/CreatePatient.vue"),
    },
    {
      path: "/list_patient",
      name: "ListPatient",
      meta: {
        permissions: ["doctor"],
      },
      component: () => import("../views/Patient/Table/ListPatient.vue"),
    },
    {
      path: "/edit_patient/:id",
      name: "EditPatient",
      meta: {
        permissions: ["doctor"],
      },
      component: () => import("../views/Patient/Form/CreatePatient.vue"),
    },
    {
      path: "/services/",
      name: "Services",
      meta: {
        permissions: ["doctor"],
      },
      component: () => import("../views/Services/Index.vue"),
    },
    {
      path: "/services/request/new",
      name: "RequestServices",
      meta: {
        permissions: ["doctor"],
      },
      component: () => import("../views/Services/Views/Index.vue"),
    },
    {
      path: "/services/request/:id",
      name: "ViewRequest",
      meta: {
        permissions: ["doctor"],
      },
      component: () => import("../views/Services/Views/Index.vue"),
    },
    {
      path: "/attendance/attendancies",
      name: "Attendancies",
      meta: {
        permissions: ["doctor"],
      },
      component: () => import("../views/Attendancies/Index.vue"),
    },
    {
      path: "/manager/management-panel",
      name: "ManagerDashboard",
      meta: {
        permissions: ["manager"],
      },
      component: () => import("../views/Manager/ManagementPanel/Index.vue"),
      // component: () => import("../views/Admin/Index"),
    },
    {
      path: "/manager/management-queue",
      name: "ManagerQueue",
      meta: {
        permissions: ["manager"],
      },
      component: () => import("../views/Manager/Queue/Queue.vue"),
    },
    {
      path: "/manager/management-finished-queue",
      name: "ManagerFinishedQueue",
      meta: {
        permissions: ["manager"],
      },
      component: () => import("../views/Manager/Queue/Finished.vue"),
    },
    {
      path: "/manager/management-forward-list",
      name: "ManagerForwardList",
      meta: {
        permissions: ["manager"],
      },
      component: () => import("../views/Manager/Queue/Forwarded.vue"),
    },
    {
      path: "/manager/profissionais",
      name: "ManagerProfessionals",
      meta: {
        permissions: ["manager"],
      },
      component: () => import("../views/Manager/Professionals/Index.vue"),
    },
    {
      path: "/admin/",
      name: "AdminDashboard",
      meta: {
        permissions: ["admin"],
      },
      component: () => import("../views/Admin/Services/Index"),
      // component: () => import("../views/Admin/Index"),
    },
    {
      path: "/admin/professionals",
      name: "AdminProfessionals",
      meta: {
        permissions: ["admin"],
      },
      component: () => import("../views/Admin/Professionals/Index.vue"),
    },
    {
      path: "/admin/professionals/create",
      name: "AdminCreateProfessionals",
      meta: {
        permissions: ["admin"],
      },
      component: () => import("../views/Admin/Professionals/Form/Index.vue"),
    },
    {
      path: "/admin/professionals/edit/:id",
      name: "AdminEditProfessional",
      meta: {
        permissions: ["admin"],
      },
      component: () => import("../views/Admin/Professionals/Form/Index.vue"),
    },
    {
      path: "/admin/services",
      name: "AdminServices",
      meta: {
        permissions: ["admin"],
      },
      component: () => import("../views/Admin/Services/Index.vue"),
    },
    {
      path: "/admin/services/request/:id",
      name: "AdminViewRequest",
      meta: {
        permissions: ["admin"],
      },
      component: () => import("../views/Admin/Services/Views/Index.vue"),
    },
    {
      path: "/admin/anamnesis",
      name: "AdminAnamnesis",
      meta: {
        permissions: ["admin"],
      },
      component: () => import("../views/Admin/Anamnesis/Index.vue"),
    },
    {
      path: "/admin/anamnesis/create",
      name: "AdminAnamnesisCreate",
      meta: {
        permissions: ["admin"],
      },
      component: () => import("../views/Admin/Anamnesis/Form/Index.vue"),
    },
    {
      path: "/admin/anamnesis/edit/:id",
      name: "AdminAnamnesisEdit",
      meta: {
        permissions: ["admin"],
      },
      component: () => import("../views/Admin/Anamnesis/Form/Index.vue"),
    },
    {
      path: "/attendance/patient/:patient_id/create/attendance",
      name: "MultiAlertAttendanceCreate",
      meta: {
        permissions: ["doctor"],
      },
      component: () =>
        import("../views/Patient/Attendance/MultiForm/Index.vue"),
    },
    {
      path: "/attendance/patient/:patient_id/:alert_id/create/attendance",
      name: "AttendancePatientCreate",
      meta: {
        permissions: ["doctor"],
      },
      component: () => import("../views/Patient/Attendance/Form/Index.vue"),
    },
    {
      path: "/attendance/patient/:patient_id/attendance/:attendance_id/edit/:alert_id",
      name: "AttendancePatientEdit",
      meta: {
        permissions: ["doctor"],
      },
      component: () => import("../views/Patient/Attendance/Form/Index.vue"),
    },
  ],
});

// Global before guard to redirect user to the login page when authentication is needed
router.beforeResolve((to, _from, next) => {
  if (to.meta && to.meta.skipAuthentication) {
    next();
  } else if (store.getters["authentication/isJwtExpired"]) {
    next({ name: "Login" });
  } else if (to.meta && to.meta.permissions) {
    if (store.getters["authentication/hasPermission"](...to.meta.permissions)) {
      next();
    } else {
      const roles = {
        admin: "administradores",
        doctor: "profissionais da saúde",
        attendance: "profissionais de atendimentos",
        manager: "gestores"
      };

      localStorage.setItem("currentUser", "{}");
      Vue.swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 4500,
        icon: "error",
        title: "MIS",
        text: `Apenas ${
          roles[to.meta.permissions] || ""
        } podem acessar esta página`,
      });
      next({ name: "Login" });
    }
  } else {
    next();
  }
});

// else if (to.meta && to.meta.permissions) {
//   if (store.getters['authentication/hasPermission'](...to.meta.permissions)) {
//     next()
//   } else {
//     next({ name: 'Login' })
//   }
// }

export default router;
